import React from 'react';

function App() {
  return (
    <>
      <nav className='navbar navbar-expand-lg navbar-transparent'>
        <div className='container'>
          <div className='navbar-translate'>
            <a className='navbar-brand' href='#!'>
              <img
                src='./assets/img/KENROD_LOGO_WHITE.png'
                alt='kendev_logo'
                style={{ height: '60px' }}
              />
            </a>
            <button
              className='navbar-toggler navbar-toggler'
              type='button'
              data-toggle='collapse'
              data-target='#navigation'
              aria-controls='navigation-index'
              aria-expanded='false'
              aria-label='Toggle navigation'>
              <span className='navbar-toggler-bar bar1'></span>
              <span className='navbar-toggler-bar bar2'></span>
              <span className='navbar-toggler-bar bar3'></span>
            </button>
          </div>
          <div
            className='collapse navbar-collapse justify-content-end'
            id='navigation'>
            <div className='navbar-collapse-header'>
              <div className='row'>
                <div className='col-6 collapse-brand'>
                  <a href='#!'>
                    <img
                      src='./assets/img/KENROD_LOGO_WHITE.png'
                      alt='kendev_logo'
                      style={{ height: '40px' }}
                    />
                  </a>
                </div>
                <div className='col-6 collapse-close text-right'>
                  <button
                    type='button'
                    className='navbar-toggler'
                    data-toggle='collapse'
                    data-target='#navigation'
                    aria-controls='navigation-index'
                    aria-expanded='false'
                    aria-label='Toggle navigation'>
                    <i className='tim-icons icon-simple-remove'></i>
                  </button>
                </div>
              </div>
            </div>
            <ul className='navbar-nav'>
              <li className='nav-item'>
                <a className='nav-link' id='btnProject' href='#!'>
                  Projects
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' id='btnContact' href='#!'>
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className='wrapper' style={{ overflow: 'hidden' }}>
        <div className='page-header'>
          <img src='./assets/img/dots.png' className='dots' alt='dots' />
          <img src='./assets/img/path4.png' className='path' alt='path' />
          <div className='container align-items-center pt-0'>
            <div className='row'>
              <div className='col-lg-6 col-md-6 mb-5'>
                <h1 className='profile-title text-left'>Kenneth Rodriguez</h1>
                <h5 className='text-on-back'>ken</h5>
                <p className='profile-description mt-0'>
                  Ken is a Software Engineer with years of experience,
                  developing Full-Stack Applications using the latest frameworks
                  and technologies. He is currently working as part of the RnD
                  Team of an international software company. His role is to
                  develop prototypes & tools, and leverage current applications,
                  to define strategies and data flow solutions, meeting the long
                  term technical and business needs. He can learn and adapt fast
                  to old and new technologies and apply them effectively. He has
                  solid analytical skills and is keen on solving complex
                  problems.
                </p>
                <div className='btn-wrapper profile pt-3 text-center text-md-left'>
                  <a
                    href='https://www.linkedin.com/in/rodriguezkenneth'
                    className='btn btn-twitter p-2'
                    data-toggle='tooltip'
                    data-original-title='Follow me'>
                    <i className='fab fa-linkedin-in fa-3x'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 mx-auto mt-md-5 pt-md-5'>
                <div className='card card-coin card-plain'>
                  <div className='card-header'>
                    <img
                      src='./assets/img/kendev_compressed.jpg'
                      className='img-center img-fluid rounded-circle'
                      alt='my_pic'
                    />
                    <h4 className='title'>Skills</h4>
                  </div>
                  <div className='card-body'>
                    <ul className='nav nav-tabs nav-tabs-primary justify-content-center'>
                      <li className='nav-item'>
                        <a
                          className='nav-link active'
                          data-toggle='tab'
                          href='#backend'>
                          Back-end
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a
                          className='nav-link'
                          data-toggle='tab'
                          href='#frontend'>
                          Front-end
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a
                          className='nav-link'
                          data-toggle='tab'
                          href='#devops'>
                          Dev-ops
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a className='nav-link' data-toggle='tab' href='#db'>
                          Database
                        </a>
                      </li>
                    </ul>
                    <div className='tab-content tab-subcategories'>
                      <div className='tab-pane active' id='backend'>
                        <div className='progress-container'>
                          <span className='progress-badge'>C#</span>
                          <div className='progress'>
                            <div
                              className='progress-bar'
                              role='progressbar'
                              aria-valuenow='60'
                              aria-valuemin='0'
                              aria-valuemax='100'
                              style={{ width: '80%' }}>
                              <span className='progress-value'>80%</span>
                            </div>
                          </div>
                        </div>
                        <div className='progress-container progress-primary'>
                          <span className='progress-badge'>Java</span>
                          <div className='progress'>
                            <div
                              className='progress-bar progress-bar-warning'
                              role='progressbar'
                              aria-valuenow='60'
                              aria-valuemin='0'
                              aria-valuemax='100'
                              style={{ width: '75%' }}>
                              <span className='progress-value'>75%</span>
                            </div>
                          </div>
                        </div>
                        <div className='progress-container progress-warning'>
                          <span className='progress-badge'>Node JS</span>
                          <div className='progress'>
                            <div
                              className='progress-bar progress-bar-warning'
                              role='progressbar'
                              aria-valuenow='60'
                              aria-valuemin='0'
                              aria-valuemax='100'
                              style={{ width: '80%' }}>
                              <span className='progress-value'>80%</span>
                            </div>
                          </div>
                        </div>
                        <div className='progress-container progress-success'>
                          <span className='progress-badge'>Express JS</span>
                          <div className='progress'>
                            <div
                              className='progress-bar progress-bar-warning'
                              role='progressbar'
                              aria-valuenow='60'
                              aria-valuemin='0'
                              aria-valuemax='100'
                              style={{ width: '80%' }}>
                              <span className='progress-value'>80%</span>
                            </div>
                          </div>
                        </div>
                        <div className='progress-container progress-info'>
                          <span className='progress-badge'>Selenium</span>
                          <div className='progress'>
                            <div
                              className='progress-bar progress-bar-warning'
                              role='progressbar'
                              aria-valuenow='60'
                              aria-valuemin='0'
                              aria-valuemax='100'
                              style={{ width: '75%' }}>
                              <span className='progress-value'>75%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='tab-pane' id='frontend'>
                        <div className='progress-container progress-success'>
                          <span className='progress-badge'>Javascript</span>
                          <div className='progress'>
                            <div
                              className='progress-bar progress-bar-warning'
                              role='progressbar'
                              aria-valuenow='60'
                              aria-valuemin='0'
                              aria-valuemax='100'
                              style={{ width: '75%' }}>
                              <span className='progress-value'>75%</span>
                            </div>
                          </div>
                        </div>
                        <div className='progress-container progress-info'>
                          <span className='progress-badge'>
                            React.js & Redux{' '}
                          </span>
                          <div className='progress'>
                            <div
                              className='progress-bar progress-bar-warning'
                              role='progressbar'
                              aria-valuenow='60'
                              aria-valuemin='0'
                              aria-valuemax='100'
                              style={{ width: '75%' }}>
                              <span className='progress-value'>75%</span>
                            </div>
                          </div>
                        </div>
                        <div className='progress-container progress-danger'>
                          <span className='progress-badge'>
                            HTML 5, CSS 3, SCSS
                          </span>
                          <div className='progress'>
                            <div
                              className='progress-bar progress-bar-danger'
                              role='progressbar'
                              aria-valuenow='60'
                              aria-valuemin='0'
                              aria-valuemax='100'
                              style={{ width: '75%' }}>
                              <span className='progress-value'>75%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='tab-pane' id='devops'>
                        <div className='progress-container progress-warning'>
                          <span className='progress-badge'>AWS</span>
                          <div className='progress'>
                            <div
                              className='progress-bar progress-bar-warning'
                              role='progressbar'
                              aria-valuenow='60'
                              aria-valuemin='0'
                              aria-valuemax='100'
                              style={{ width: '75%' }}>
                              <span className='progress-value'>75%</span>
                            </div>
                          </div>
                        </div>
                        <div className='progress-container progress-info'>
                          <span className='progress-badge'>Docker</span>
                          <div className='progress'>
                            <div
                              className='progress-bar progress-bar-warning'
                              role='progressbar'
                              aria-valuenow='60'
                              aria-valuemin='0'
                              aria-valuemax='100'
                              style={{ width: '75%' }}>
                              <span className='progress-value'>75%</span>
                            </div>
                          </div>
                        </div>
                        <div className='progress-container progress-success'>
                          <span className='progress-badge'>Swarm</span>
                          <div className='progress'>
                            <div
                              className='progress-bar progress-bar-warning'
                              role='progressbar'
                              aria-valuenow='60'
                              aria-valuemin='0'
                              aria-valuemax='100'
                              style={{ width: '75%' }}>
                              <span className='progress-value'>75%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='tab-pane' id='db'>
                        <div className='progress-container progress-success'>
                          <span className='progress-badge'>Mongo DB</span>
                          <div className='progress'>
                            <div
                              className='progress-bar progress-bar-warning'
                              role='progressbar'
                              aria-valuenow='60'
                              aria-valuemin='0'
                              aria-valuemax='100'
                              style={{ width: '75%' }}>
                              <span className='progress-value'>75%</span>
                            </div>
                          </div>
                        </div>
                        <div className='progress-container progress-warning'>
                          <span className='progress-badge'>SQL</span>
                          <div className='progress'>
                            <div
                              className='progress-bar progress-bar-warning'
                              role='progressbar'
                              aria-valuenow='60'
                              aria-valuemin='0'
                              aria-valuemax='100'
                              style={{ width: '75%' }}>
                              <span className='progress-value'>75%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='section' id='project'>
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-md-6'>
                <div className='row justify-content-between align-items-center'>
                  <div
                    id='cleeneco'
                    className='carousel slide'
                    data-ride='carousel'>
                    <ol className='carousel-indicators'>
                      <li
                        data-target='#cleeneco'
                        data-slide-to='0'
                        className='active'></li>
                      <li data-target='#cleeneco' data-slide-to='1'></li>
                      <li data-target='#cleeneco' data-slide-to='2'></li>
                      <li data-target='#cleeneco' data-slide-to='3'></li>
                    </ol>
                    <div className='carousel-inner' role='listbox'>
                      <div className='carousel-item active'>
                        <img
                          className='d-block'
                          src='https://res.cloudinary.com/de6uaupbi/image/upload/v1543040300/rodriguezkenneth/home.jpg'
                          alt='Home'
                        />
                        <div className='carousel-caption d-none d-md-block'></div>
                      </div>
                      <div className='carousel-item'>
                        <img
                          className='d-block'
                          src='https://res.cloudinary.com/de6uaupbi/image/upload/v1543040300/rodriguezkenneth/conatct.jpg'
                          alt='Contact'
                        />
                        <div className='carousel-caption d-none d-md-block'></div>
                      </div>
                      <div className='carousel-item'>
                        <img
                          className='d-block'
                          src='https://res.cloudinary.com/de6uaupbi/image/upload/v1543040299/rodriguezkenneth/book.jpg'
                          alt='Book'
                        />
                        <div className='carousel-caption d-none d-md-block'></div>
                      </div>
                      <div className='carousel-item'>
                        <img
                          className='d-block'
                          src='https://res.cloudinary.com/de6uaupbi/image/upload/v1543040952/rodriguezkenneth/rent.jpg'
                          alt='Rent'
                        />
                        <div className='carousel-caption d-none d-md-block'></div>
                      </div>
                    </div>
                    <a
                      className='carousel-control-prev'
                      href='#cleeneco'
                      role='button'
                      data-slide='prev'>
                      <i className='tim-icons icon-minimal-left'></i>
                    </a>
                    <a
                      className='carousel-control-next'
                      href='#cleeneco'
                      role='button'
                      data-slide='next'>
                      <i className='tim-icons icon-minimal-right'></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-5'>
                <h1 className='profile-title text-left'>Cleeneco</h1>
                <h5 className='text-on-back'>proj</h5>
                <p className='profile-description text-left'>
                  CLEENECO.COM is a web-based application that will show you
                  everything you need to know about the company and its
                  services. It has a dynamic booking form that will let you book
                  cleaning and rental services easily using the browser of your
                  computer or mobile phone. It also has a dashboard for the
                  clients to monitor their profile and transactions, and for the
                  admins to monitor bookings, transactions and sales. It also
                  has a modern discount system as a marketing strategy to boost
                  sales and for the clients to enjoy.
                </p>
                <div className='btn-wrapper pt-3'>
                  <a
                    href='https://rocky-springs-61586.herokuapp.com/'
                    className='btn btn-simple btn-primary'>
                    <i className='tim-icons icon-bulb-63'></i> Check it!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='section' id='project2'>
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-md-5'>
                <h1 className='profile-title text-left'>Mega Q Mart</h1>
                <h5 className='text-on-back'>proj</h5>
                <p className='profile-description text-left'>
                  Modern Website for Mega Q Mart; An established wet & dry
                  drop-off market (bagsakan) along the busy highway of Edsa,
                  Cubao. It is famous for its cheap and farm-direct fresh
                  produce coming from up north. We value cleanliness and safety,
                  that is why we have sanitation and security personnel on-duty
                  24/7.
                </p>
                <div className='btn-wrapper pt-3'>
                  <a
                    href='https://admiring-bell-aac415.netlify.app/'
                    className='btn btn-simple btn-primary'>
                    <i className='tim-icons icon-bulb-63'></i> Visit Site
                  </a>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row justify-content-between align-items-center'>
                  <div
                    id='megaqmart'
                    className='carousel slide'
                    data-ride='carousel'>
                    <ol className='carousel-indicators'>
                      <li
                        data-target='#megaqmart'
                        data-slide-to='0'
                        className='active'></li>
                      <li data-target='#megaqmart' data-slide-to='1'></li>
                      <li data-target='#megaqmart' data-slide-to='2'></li>
                    </ol>
                    <div className='carousel-inner' role='listbox'>
                      <div className='carousel-item active'>
                        <img className='d-block' src='mqm_1.jpg' alt='Home' />
                        <div className='carousel-caption d-none d-md-block'></div>
                      </div>
                      <div className='carousel-item'>
                        <img
                          className='d-block'
                          src='mqm_2.jpg'
                          alt='Contact'
                        />
                        <div className='carousel-caption d-none d-md-block'></div>
                      </div>
                      <div className='carousel-item'>
                        <img className='d-block' src='mqm_3.jpg' alt='Book' />
                        <div className='carousel-caption d-none d-md-block'></div>
                      </div>
                    </div>
                    <a
                      className='carousel-control-prev'
                      href='#megaqmart'
                      role='button'
                      data-slide='prev'>
                      <i className='tim-icons icon-minimal-left'></i>
                    </a>
                    <a
                      className='carousel-control-next'
                      href='#megaqmart'
                      role='button'
                      data-slide='next'>
                      <i className='tim-icons icon-minimal-right'></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className='section' id='contact'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='card card-plain'>
                  <div className='card-header'>
                    <h1 className='profile-title text-left'>Contact</h1>
                    <h5 className='text-on-back'>info</h5>
                  </div>
                  <div className='card-body'>
                    <form>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label>First Name</label>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='Kenneth'
                            />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label>Last Name</label>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='Rodriguez'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label>Email address</label>
                            <input
                              type='email'
                              className='form-control'
                              placeholder='Ken@email.com'
                            />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label>Phone</label>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='001-12321345'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='form-group'>
                            <label>Message</label>
                            <textarea
                              type='text'
                              className='form-control'
                              placeholder='Hello there!'></textarea>
                          </div>
                        </div>
                      </div>
                      <button
                        type='submit'
                        className='btn btn-primary btn-round float-right'
                        rel='tooltip'
                        data-original-title="Can't wait for your message"
                        data-placement='right'>
                        Send email
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className='col-md-4 ml-auto'>
                <img
                  src='./assets/img/path2.png'
                  className='path'
                  alt='path2'
                />

                <div className='info info-horizontal'>
                  <div className='icon icon-primary'>
                    <i className='tim-icons icon-badge'></i>
                  </div>
                  <div className='description'>
                    <h4 className='info-title'>Shoot me an e-mail</h4>
                    <p>
                      Kenneth Rodriguez
                      <br /> mail@kennethrodriguez.com
                      <br />{' '}
                      <span className='text-white'>+63 999 369 5405</span>
                      <br /> Mon - Fri, 9:00 - 20:00
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className='footer'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 mx-auto text-center'>
                <img
                  src='./assets/img/KENROD_LOGO_WHITE.png'
                  alt='kendev_logo'
                  style={{ height: '60px' }}
                  className='mb-4'
                />
                <p className='mb-0'>
                  The only way to do great work, is to love what you do
                </p>
                <footer className='blockquote-footer'>Steve Jobs</footer>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default App;
